<template>
  <a-table :columns="columns" :source="lessons" :loading="loading">
    <template #toolbar(search)>
      <h4 class="text-2xl p-2">Clases registradas</h4>
    </template>
    <template #toolbar>
      <a-period-accessor v-model="period" class="mr-2" />
    </template>

    <template #td(actions)="{ item }">
<!--      <a-->
<!--        class="px-4 py-2 rounded-xl active:scale-95 transform transition-transform hover:bg-primary hover:bg-opacity-10"-->
<!--        :href="$repository.courseSections.reports.lessonRegister({ ...item })"-->
<!--        rel="noreferrer"-->
<!--        target="_blank">-->
<!--        Generar reporte-->
<!--      </a>-->
        <a-dropdown>
            <template #menu>
                <a-dropdown-item :href="$repository.courseSections.reports.lessonRegister({ ...item })">
                    Generar reporte
                </a-dropdown-item>
<!--                    <a-dropdown-item :to="#">-->
<!--                        Editar notas-->
<!--                    </a-dropdown-item>-->
                <a-dropdown-item v-if="!user.isLowAdmin" :to="{ name: 'lessons.show', params: { id: item.id }, query: { coursesection: item.course_section_id } }">
                    Editar notas
                </a-dropdown-item>
            </template>
        </a-dropdown>

    </template>
  </a-table>
</template>

<script>
import moment from "@/filters/moment";
import {mapState} from "vuex";

export default {
  data: () => ({
    period: null,
    loading: false,
    lessons: [],
    coordinate_specializations: [],
    columns: [
      { title: 'Tema', key: 'subject' },
      { title: 'Fecha sugerida', key: 'date', mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : ''},
      { title: 'Fecha de registro', key: 'registered_at', mutate: ({ key }) => key ? moment(key, 'DD-MM-YYYY') : '' },
      { title: 'Materia', key: 'course.title' },
      { title: 'Sección', key: 'section.letter_code' },
      { title: '¿Evaluada?', key: 'evaluated', mutate: ({ key }) => key ? 'SI' : 'NO'},
      { title: 'Acciones', key: '', slot: 'actions' }
    ]
  }),
  computed: {
    ...mapState({
      user: ({ session }) => (session.user)
    })
  },
  methods: {
    fetch (period) {

      if(this.user.isCoordinator){
        this.$repository.teachers
          .coordinateSpecializations(this.user.teacher.id)
          .then(({ data }) => {
            this.coordinate_specializations = data;
          })
      }

      this.$repository.teachers
        .lessons(this.$route.params.id, { period, limit: 0, with: 'course,section' })
        .then(({ data }) => {
   
          this.lessons = this.mappedCourseSection(data)
        })
        ._loading(() => this.loading = !this.loading)
    },
    mappedCourseSection(data) {
      if (this.user.isCoordinator) {
        return data.flatMap(it => this.coordinate_specializations.find(id => id.id === it.section.specialization_id) ? it : []).sort((a, b) => a.date - b.date)
      } else {
        return data
      }
    }
  },
  watch: {
    period(period) {
      if (period) {
        this.fetch(period)
      }
    }
  }
}
</script>
